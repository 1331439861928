import React from "react";
import { Box, Text } from "components";
import AirlineLogo from "components/Reusable/AirlineLogo";
import { useTranslation } from "react-i18next";
import { useGetSettingsQuery } from "redux/queries/settings.query";
import { isArabic } from "utils/arabisation.util";

const Footer = ({ airlineName, mediaUrl, gate, type, belt, terminal }) => {
  const { data: settingsData = {} } = useGetSettingsQuery();
  const isTerminalFilteringActive = settingsData.terminalFilter !== "disabled";

  const { i18n, t } = useTranslation();
  const shouldReverseOrder = isArabic(i18n);
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      direction={shouldReverseOrder ? "row-reverse" : undefined}
      pH="s"
      pV="xs"
      bT
    >
      <AirlineLogo airlineName={airlineName} mediaUrl={mediaUrl} />
      {type === "departure" && gate && (
        <Text fontSize="m">
          {gate?.includes("Gate")
            ? gate
            : `${t("details.flightInfo_departures_gate")} ${gate}`}
        </Text>
      )}
      {type === "arrival" && belt && belt?.length > 0 && (
        <Text fontSize="m">
          {`${t("details.flightInfo_arrivals_belt")} ${belt}`}
        </Text>
      )}
      {isTerminalFilteringActive && (
        <Text fontSize="m">
          {terminal.includes("Terminal")
            ? terminal
            : `${t("details.flightInfo_terminal")} ${terminal}`}
        </Text>
      )}
    </Box>
  );
};

export default Footer;
