import { ROUTES } from "api";
import { LANGUAGE_CODES } from "configs/constants";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getClientCredentials } from "services/auth.service";

const customBackend = {
  type: "backend",
  read: async (language, namespace, callback) => {
    try {
      const { clientId, clientSecret, uniqueDeviceIdentifier } =
        await getClientCredentials();

      const response = await fetch(
        `${window._env_?.REACT_APP_API_URL}${ROUTES.language}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "shared-key": window._env_.REACT_APP_SHARED_KEY,
            "client-id": clientId,
            "client-secret": clientSecret,
            "device-id": uniqueDeviceIdentifier,
          },
          body: JSON.stringify({
            language,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      const parsedResponse = await response.json();

      if (parsedResponse.data) {
        callback(null, parsedResponse.data);
      } else {
        callback(new Error("No translations found"));
      }
    } catch (error) {
      callback(error, false);
    }
  },
};

const browserLanguage =
  navigator.language.split("-")?.[0] || LANGUAGE_CODES.ENGLISH;

const initI18n = () => {
  return new Promise((resolve, reject) => {
    i18n
      .use(customBackend)
      .use(initReactI18next)
      .init(
        {
          lng: browserLanguage,
          fallbackLng: false,
          interpolation: {
            escapeValue: false,
          },
          react: {
            useSuspense: true,
          },
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
  });
};

export { i18n, initI18n };
